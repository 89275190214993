<template>
  <div class="" v-if ="nextLesson.material && nextLesson.status_str">

    <div class="alert alert-warning" v-if="nextLesson.next_lesson_status == 'closed'">
      <div v-html="nextLesson.status_str"></div>
    </div>
    <div class="alert alert-success" v-if="nextLesson.next_lesson_status == 'opened'">
      <div v-html="nextLesson.status_str"></div>
      <a class="btn btn-primary mt-1" :href="nextLesson.material.link">Перейти к уроку</a>
      
    </div>
  </div>
</template>

<script>
import { API_URL } from '@/config.js';
import { mapGetters, useStore } from 'vuex';
import { computed } from 'vue';
import axiosConfig from '@/lib/axiosConfig';


export default {
  props: {
    articleItem: {
      type: Object,
      required: true
    }
  },


  data() {
    return {
      nextLesson: {},
    };
  },
  computed: {
    ...mapGetters('app', ['isMobile']), 
  },  
  setup(){
    const store = useStore();

    const user = computed(() => store.getters['user/getUser']);
    return {
      user
    }

  },
  methods: {
    fetchArticleNextLesson() {
      //console.log(111);
      axiosConfig
        .get(API_URL, {
          params: {
            action: 'get_article_next_lesson',
            articleId: this.articleItem.id,
            material_collection_priority: this.articleItem.material_collection_priority,
            collectionId: this.articleItem.collection_id
          },
        })
        .then(response => {
          this.nextLesson = response.data.next_lesson;
          //console.log(this.nextLesson);
        })

        .catch(error => {
          console.error('Error fetching rating results:', error);

          throw error;
        });

    }
  },
  mounted() {
    this.fetchArticleNextLesson();
  },
};
</script>

<style>
/* Custom styles for the component */
</style>
