<template>
  <div class="inline-block me-2">
    <a :id="'time' + startTime"></a>
    <button @click="showModal" class="btn btn-sm btn-light nowrap">
      <i class="bi bi-play"></i> найти в видео ({{ formattedTime }})
      
    </button>

    <div v-if="showVideoModal" class="modal fade show" tabindex="-1" aria-labelledby="videoModalLabel" aria-hidden="true" style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="videoModalLabel">Видео</h5>
            <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <iframe
              :src="youtubeSrc"
              frameborder="0"
              allow="autoplay; encrypted-media"
              allowfullscreen
              style="width: 100%; height: 400px;"
            ></iframe>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal">Закрыть</button>
          </div>
        </div>
      </div>
    </div>
    <overlay-component :visible="showVideoModal" class="onfront"></overlay-component>
  </div>
</template>

<script>
import OverlayComponent from '@/components/OverlayComponent.vue';


export default {
  props: {
    startTime: {
      type: Number,
      required: true,
    },
    youtubeLink: {
      type: String,
      required: true,
    },
  },
  components: {
    OverlayComponent,
  },
  data() {
    return {
      showVideoModal: false,
    };
  },
  computed: {
    youtubeSrc() {
      const startTime = Math.floor(this.startTime); 
      return `${this.youtubeLink}?start=${startTime}&autoplay=1`;
    },
    formattedTime() {
      const hours = Math.floor(this.startTime / 3600);
      const minutes = Math.floor((this.startTime % 3600) / 60);
      const seconds = this.startTime % 60;

      // Если часов нет, выводим только минуты и секунды
      if (hours > 0) {
        return [
          hours.toString().padStart(2, '0'),
          minutes.toString().padStart(2, '0'),
          seconds.toString().padStart(2, '0')
        ].join(':');
      } else {
        return [
          minutes.toString().padStart(2, '0'),
          seconds.toString().padStart(2, '0')
        ].join(':');
      }
    }
  },


  methods: {
    showModal() {
      this.showVideoModal = true;
    },
    closeModal() {
      this.showVideoModal = false;
    },
  },
};
</script>
