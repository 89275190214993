<template>
  <div class="container mt-4">
    <div v-if="channel">

      <div v-if="!isPreview">
        <div class="row mb-3">
          <div class="col-12">
            <SearchBarComponent searchType="channel" :channelId="channel.id"/>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <img :src="channel.thumbs.big" class="mb-3 cover" v-if="channel.thumbs" />
          </div>
          <div class="col-8 d-flex align-items-center">
            <div>
              <div class="text-muted small" v-if="0">Автор: <a :href="'https://t.me/' + channel.username">{{channel.username}}</a></div>
              <h1>{{ channel.caption }}</h1>
              <div v-html="channel.description"></div>
            </div>
          </div>
        </div>



        <div id="collections-list" class="mb-4" v-if="channel.collections.length">
          <h2>Плейлисты в канале</h2>
          <div class="row d-flex">
            <div class="col-md-4" v-for="collection in channel.collections" :key="collection.id">
              <div class="card mb-3 h-100">
                <div class="card-body d-flex flex-column">
                  <div class="d-flex align-items-start mb-2">
                    <div class="mr-2" v-if="collection.thumbs.big">
                      <img :src="collection.thumbs.big" alt="" class="thumb">
                    </div>
                    <div class="thumb empty-thumb" v-if="!collection.thumbs.big">
                      <i class="bi bi-camera"></i>
                    </div>
                  </div>
                  <div>
                    <h4 class="card-title mb-1">
                      <router-link :to="{ name: 'Collection', params: { id: collection.hash } }">
                        {{ collection.caption }}
                      </router-link>
                    </h4>
                    <p v-if="collection.materials_count" class="card-text mb-0">{{ collection.materials_count }} материалов</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="collections-list" class="mb-4" v-if="channel.courses.length">
          <h2>Курсы в канале</h2>
          <div class="row d-flex">
            <div class="col-md-4" v-for="collection in channel.courses" :key="collection.id">
              <div class="card mb-3 h-100">
                <div class="card-body d-flex flex-column">
                  <div class="d-flex align-items-start mb-2">
                    <div class="mr-2" v-if="collection.thumbs.big">
                      <img :src="collection.thumbs.big" alt="" class="thumb">
                    </div>
                    <div class="thumb empty-thumb" v-if="!collection.thumbs.big">
                      <i class="bi bi-camera"></i>
                    </div>
                  </div>
                  <div>
                    <h4 class="card-title mb-1">
                      <router-link :to="{ name: 'Collection', params: { id: collection.hash } }">
                        {{ collection.caption }}
                      </router-link>
                    </h4>
                    <p v-if="collection.materials_count" class="card-text mb-0">{{ collection.materials_count }} материалов</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3" v-if="!isPreview">
        <h2>Материалы</h2>
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: materialType === 'newest' }"
              @click="selectMaterialType('newest')"
              href="javascript:void(0);"
            >Новые</a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: materialType === 'oldest' }"
              @click="selectMaterialType('oldest')"
              href="javascript:void(0);"
            >Старые</a>
          </li>
        </ul>
      </div>

      <ul class="nav nav-pills justify-content-center mb-3">
        <li class="nav-item">
          <button
            class="btn btn-outline-primary me-2 mb-2"
            :class="{ active: selectedCategoryId === -1 }"
            @click="selectCategory({ id: -1 })"
          >
            Показать все
          </button>
        </li>
        <li v-for="category in channel.categories" :key="category.id" class="nav-item">
          <button
            class="btn btn-outline-primary me-2 mb-2 category-btn"
            :class="{ active: selectedCategoryId === category.id }"
            @click="selectCategory(category)"
          >
            <span class="caption">{{ category.caption }}</span>
            <span v-if="category.materials_count !== '0'" class="small">({{ category.materials_count }})</span>
          </button>
        </li>
        <li class="nav-item">
          <router-link to="/materials" v-if="hasEditAccess">
            <button class="btn btn btn-outline-primary">Добавить материал</button>
          </router-link>
        </li>
      </ul>



      <div class="row row-cols-1 row-cols-md-3 g-4 materials-cards">
        <div v-for="material in channel.materials" :key="material.id" class="col">
          <div class="card h-100">
            <div class="card-body">
              <div class="text-center mb-2 thumb-container" v-if="material.smallpicture" >
                <div v-if="material.duration" class="duration"><span><i class="bi bi-clock"></i> {{ material.duration }}</span></div>
                <img
                  v-if="material.smallpicture"
                  :src="material.smallpicture"
                  alt=""
                  class="img-thumbnail img-cover"
                />
              </div>



              <div class="bottom-part" v-if="isPreview">
                <h5 class="card-title">{{ material.caption }}</h5>
                <button class="btn btn-primary btn-sm w-100 w-sm-auto mt-2" @click="selectMaterialForBot(material.hash)">Открыть</button>
              </div>
              <div class="bottom-part" v-if="!isPreview">

                <h5 class="card-title">
                  <router-link :to="{ name: 'Article', params: { id: material.hash } }">{{ material.caption }}</router-link>
                </h5>

                <div v-if="material.timeadd_original">
                  <span class="small text-muted">{{ material.timeadd_original }}</span>
                </div>

                <div class="small text-info" v-if="material.status_caption">{{ material.status_caption }}</div>

                <div v-if="material.open_status === 0">
                  <span class="badge bg-danger">🔒 Закрыто</span>&nbsp;
                  <span v-if="material.open_by_date" class="text-muted small">Доступ будет открыт {{ material.open_by_date }}</span>
                  <span v-if="material.open_by_test_score" class="text-muted small">Нужна оценка за предыдущий тест: {{ material.open_by_test_score }} баллов</span>
                </div>
                <div v-else-if="material.open_status === 1">
                  <span class="badge bg-success">Открыто</span>&nbsp;
                </div>
                <div v-else-if="material.open_status === 2">
                  <span class="badge bg-success">Открыто</span>&nbsp;
                  <span v-if="material.open_by_test_score" class="text-muted small">Оценка за предыдущий тест: {{ material.open_status_test_score }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center">
        <spinner-component :visible="materialsLoading" class="mt-4"></spinner-component>
      </div>
    </div>
    <div v-else>
      <spinner-component :visible="true"></spinner-component>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore, mapGetters } from 'vuex';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import SearchBarComponent from './SearchBarComponent.vue';
export default {
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters('app', ['isMobile', 'configData', 'isPreview']),
  },
  components: {
    SpinnerComponent,
    SearchBarComponent
  },
  methods: {
    selectMaterialType(type) {
      this.materialType = type;
      this.page = 0;
      this.allLoaded = false;
      this.channel.materials = [];
      this.fetchMaterials();
    },

    // fetchMaterials() {
    //   if (!this.channel || this.materialsLoading || this.allLoaded) return; // Проверка на наличие канала

    //   this.materialsLoading = true;
    //   const payload = {
    //     channelId: this.channel.id,
    //     materialsType: this.materialType,
    //     page: this.page,
    //   };

    //   if (this.selectedCategoryId > -1) {
    //     payload.categoryId = this.selectedCategoryId;
    //   }

    //   this.$store.dispatch('channel/fetchChannelMaterials', payload)
    //     .then((data) => {
    //       if (data.channel_materials.length) {
    //         this.channel.materials.push(...data.channel_materials);
    //         this.page += 1;
    //       } else {
    //         this.allLoaded = true;
    //       }
    //       this.materialsLoading = false;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       this.materialsLoading = false;
    //     });
    // },

    fetchCollections() {
      const payload = {
        channelId: this.channel.id,
      };

      this.$store.dispatch('collection/fetchCollections', payload)
        .then((data) => {
          this.channel.collections = data.collections;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    selectCategory(category) {
      this.selectedCategoryId = category.id;
      this.selectMaterialType(this.materialType);
    },

    selectMaterialForBot(hash) {
      this.$store.dispatch('tgconnector/sendCommandToBot', {
        params: { material_hash: hash, command: 'show_channel_material' },
      });
    },
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const channelId = ref(null);
    const channel = ref(null);
    const materialType = ref('newest');
    const selectedCategoryId = ref(-1);
    const materialsLoading = ref(false);
    const page = ref(0);
    const allLoaded = ref(false);
    const hasEditAccess = computed(() => store.getters['user/hasEditAccess']);

    const fetchMaterials = () => {
      if (!channel.value || materialsLoading.value || allLoaded.value) return; // Проверка на наличие канала

      materialsLoading.value = true;
      const payload = {
        channelId: channel.value.id,
        materialsType: materialType.value,
        page: page.value,
      };

      if (selectedCategoryId.value > -1) {
        payload.categoryId = selectedCategoryId.value;
      }

      store.dispatch('channel/fetchChannelMaterials', payload)
        .then((data) => {
          if (data.channel_materials.length) {
            channel.value.materials.push(...data.channel_materials);
            page.value += 1;
          } else {
            allLoaded.value = true;
          }
          materialsLoading.value = false;
        })
        .catch((error) => {
          console.log(error);
          materialsLoading.value = false;
        });
    };

    const handleScroll = () => {
      const bottomOfWindow = window.innerHeight + window.scrollY >= document.body.offsetHeight;
      if (bottomOfWindow) {
        fetchMaterials();
      }
    };

    onMounted(() => {
      channelId.value = route.params.id;

      const categoryId = route.query.categoryId;
      if (categoryId !== undefined) {
        selectedCategoryId.value = parseInt(categoryId);
      }

      store.dispatch('channel/fetchChannel', { channelId: channelId.value })
        .then((data) => {
          channel.value = data.channel;
          document.title = data.channel.caption;

          if (categoryId !== undefined) {
            const category = data.channel.categories.find(cat => cat.id === selectedCategoryId.value);
            if (category) {
              store.commit('setSelectedCategoryId', selectedCategoryId.value);
              store.dispatch('channel/fetchChannelMaterials', {
                channelId: data.channel.id,
                materialsType: materialType.value,
                categoryId: selectedCategoryId.value,
              }).then((materialsData) => {
                channel.value.materials = materialsData.channel_materials;
              }).catch((error) => {
                console.log(error);
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });

      window.addEventListener('scroll', handleScroll);
    });

    return {
      channelId,
      channel,
      materialType,
      selectedCategoryId,
      hasEditAccess,
      fetchMaterials,
      materialsLoading,
      page,
      allLoaded,
    };
  },
};
</script>

<style>
.cover {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

#collections-list {
  padding: 1rem;
  background: #f4eeff;
  border-radius: 6px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

#collections-list .thumb {
  width: 100%;
  height: auto;
}

#collections-list .thumb.empty-thumb {
  width: 100%;
  height: 120px;
}

.thumb-container {
  position: relative;
}

.thumb-container .duration{
  position: absolute;
  bottom: 10px;
  left: 15px;
  background: #FFF;
  font-size: 0.8em;
  border-radius: 6px;
  padding: 2px 6px;
  color: #bbb;

}

.img-thumbnail {
  border: 0;
}

.materials-cards .card-body {
  padding: 0;
}

.materials-cards .card-body .img-cover {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.materials-cards .card-body .img-thumbnail {
  padding: 0;
}

.bottom-part {
  padding: 0 1rem 1rem 1rem;
}

.category-btn{
  display: flex;
  align-items: center;
}

.category-btn .caption{
  max-width: 120px;
  margin-right: 6px;
  text-wrap: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;

}
</style>
