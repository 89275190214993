<template>
  <div class="modal fade show" tabindex="-1" role="dialog" v-if="show" style="display: block;">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Сообщение об ошибке</h5>
          <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Выделенный текст:</p>
          <p>{{ selectedText }}</p>
          <textarea v-model="errorMessage" placeholder="Опишите ошибку..." class="form-control"></textarea>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">Закрыть</button>
          <button type="button" class="btn btn-primary" @click="sendErrorReport">Отправить</button>
        </div>
      </div>
    </div>
  </div>
  <overlay-component :visible="show" class="onfront"></overlay-component>
</template>

<script>

import OverlayComponent from '@/components/OverlayComponent.vue';
import { useToast } from "vue-toastification";

export default {
  props: {
    selectedText: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close'], // Добавьте эту строку
  components: {
    OverlayComponent
  },  
  data() {
    return {
      errorMessage: '',
    };
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },

    sendErrorReport() {
      const currentUrl = window.location.href; // Получаем текущий адрес страницы
      const context = this.getContextAroundSelection(this.selectedText); // Получаем контекст

      return this.$store.dispatch('app/sendErrorReport', {
        selectedText: this.selectedText,
        additionalInfo: this.errorMessage,
        page: currentUrl,
        context: context,
      })
      .then(response => {
        //console.log(response);
        if (response && response.result === 'success') {
          //console.log('Error report sent:', response.result);
          const toast = useToast();
          this.errorMessage = '';

          toast.success("Спасибо! Сообщение об ошибке отправлено.", {
            timeout: 2000,
          });          
          this.closeModal(); // Закрываем модалку после отправки
        } else {
          //console.error('Ошибка: Не получен корректный ответ от сервера', response);
        }
      })
      .catch(error => {
        console.error('Error sending report:', error);
        //alert('Ошибка при отправке сообщения об ошибке.');
      });
    },


    getContextAroundSelection(selectedText) {
      const pageText = document.body.innerText; // Получаем весь текст страницы
      const selectionStart = pageText.indexOf(selectedText); // Позиция выделенного текста

      if (selectionStart === -1) {
        return ''; // Если текст не найден
      }

      const contextRange = 30; // Количество символов до и после выделения
      const start = Math.max(0, selectionStart - contextRange); // Начало контекста
      const end = Math.min(pageText.length, selectionStart + selectedText.length + contextRange); // Конец контекста

      const context = pageText.slice(start, end);
      return context;
    }


  },
};
</script>
