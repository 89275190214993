<template>
  <a name="timecodes"></a>
  <div class="mt-4">
    <h2>Таймкоды</h2>
    <button @click="copy(timecodes_raw)" class="btn btn-success mb-4">
      Копировать таймкоды
    </button>

    <ul class="list-group mb-3">
      <li v-for="timecode in timecodes" :key="timecode.id" class="list-group-item d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <span class="badge bg-primary me-2">{{ timecode.timestamp }}</span>
          <div> {{ timecode.name }}</div>
        </div>

        <ButtonVideoComponent :startTime="timecode.seconds" :youtubeLink="articleItem.youtube_link"></ButtonVideoComponent>
            
        <!-- <a :href="articleItem.hash + '?start='+timecode.seconds+'&autoplay=1'" class="btn btn-light"><i class="bi bi-arrow-right"></i></a> -->
      </li>
    </ul>


  </div>
</template>


<script>
import { API_URL } from '@/config.js';
import { useToast } from "vue-toastification";
import useClipboard from 'vue-clipboard3'
import { mapGetters } from 'vuex';
import ButtonVideoComponent from '@/components/ButtonVideoComponent.vue';

export default {
  components: {
      ButtonVideoComponent
  },
  
  data() {
    return {
      timecodes: []
    };
  },
  setup(){
    const { toClipboard } = useClipboard()

    const copy = async (text) => {
      try {
        await toClipboard(text)
        //console.log('Copied to clipboard')
        const toast = useToast();

        // or with options
        toast.success("Скопировано в буфер обмена", {
          timeout: 2000
        });
      } catch (e) {
        console.error(e)
      }
    }

    return {
      copy
    }
  },
  computed: {
    ...mapGetters("article", ["getArticleItem"]),
    articleItem: {
      get() {
        return this.getArticleItem || {};
      },
    }
  },
  methods: {
    getTimecodes() {
      this.$axios
        .get(API_URL, {
          params: {
            action: 'get_timecodes',
          }
        })
        .then((response) => {
          this.timecodes = response.data.timecodes;
          this.timecodes_raw = response.data.timecodes_raw;
          
        })
        .catch((error) => {
          console.error('Error fetching timecodes:', error);
        });
    }
  },
  mounted() {
    this.getTimecodes();
  }
};
</script>

<style>
/* Стили для компонента */
</style>
