<template>
  <div class="input-group">
    <input
      type="text"
      class="form-control"
      v-model="searchQuery"
      @keyup.enter="performSearch"
      :placeholder="placeholderText"
      aria-label="Поисковый запрос"
      aria-describedby="button-search"
    />
    <button 
      class="btn btn-outline-secondary" 
      type="button" 
      id="button-search" 
      @click="performSearch"
    >
      🔍
    </button>
  </div>
</template>


<script>
export default {
  props: {
    searchType: {
      type: String,
      default: 'global' // Тип поиска, например, 'global', 'channel' и т.д.
    },
    channelId: {
      type: String,
      default: ''
    },
    additionalParams: {
      type: Object,
      default: () => ({}) // Дополнительные параметры, которые можно передать при поиске
    }
  },
  data() {
    return {
      searchQuery: '' // Модель для строки поиска
    };
  },
  computed: {
    placeholderText() {
      // В зависимости от типа поиска возвращаем текст для плейсхолдера
      return this.searchType === 'channel'
        ? 'Поиск по каналу...'
        : 'Поиск по всему сайту...';
    }
  },
  methods: {
    performSearch() {
      if (this.searchQuery.trim()) {
        // Универсальный параметр, который может быть ID канала, плейлиста и т.д.
        const contextId = this.searchType === 'channel' ? this.channelId : (this.searchType === 'playlist' ? this.playlistId : null);

        // Переход на страницу поиска с параметрами query, type и contextId через путь
        this.$router.push({
          name: 'Search',
          params: {
            query: this.searchQuery,
            type: this.searchType,
            contextId
          }
        });
        this.searchQuery = ''; // Очистить строку поиска после перехода
      }
    }
  }
};

</script>
