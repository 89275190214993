<template>
  <div class="inline-block clear-both">
    <a :id="'time' + startTime"></a>
    <button @click="fetchFragmentsData" class="btn btn-sm btn-light nowrap">
      <i class="bi bi-play"></i> найдено {{ fragmentCount }} фрагментов
    </button>

    <!-- Модальное окно для отображения фрагментов -->
    <div v-if="showFragmentModal" class="modal fade show" tabindex="-1" aria-labelledby="fragmentModalLabel" aria-hidden="true" style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="fragmentModalLabel">Связанные фрагменты</h5>
            <button type="button" class="btn-close" @click="closeFragmentModal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <ul v-if="relatedFragments.length" class="list-group">
              <li v-for="fragment in relatedFragments" :key="fragment.fragment_child_id" class="list-group-item">
                <h6><a :href="fragment.article_url + '#time' + fragment.start_time" target="_blank">{{ fragment.article_title }}</a></h6>
                <p><span class="badge bg-primary me-1">{{formattedTime(fragment.start_time)}}</span>{{ fragment.fragment_text }}</p>
                <p class="micro text-muted">{{ fragment.score }}</p>
                <button @click="goToFragment(fragment.article_url + '#time' + fragment.start_time)" class="btn btn-primary btn-sm">Перейти</button>
              </li>
            </ul>
            <p v-else>Фрагменты не найдены.</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeFragmentModal">Закрыть</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    startTime: {
      type: Number,
      required: true,
    },
    articleId: {
      type: Number,
      required: true,
    },
    fragmentCount: { // Новый пропс для количества фрагментов
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      showFragmentModal: false,
    };
  },
  computed: {
    ...mapGetters('article', ['getRelatedFragments']),
    relatedFragments() {
      return this.getRelatedFragments || [];
    }
  },
  methods: {
    ...mapActions('article', ['fetchRelatedFragments']),
    fetchFragmentsData() {
      this.fetchRelatedFragments({ articleId: this.articleId, startTime: this.startTime })
        .then(() => {
          this.showFragmentModal = true;
        })
        .catch(error => {
          console.error('Ошибка при получении фрагментов:', error);
        });
    },
    formattedTime(startTime) {
      const hours = Math.floor(startTime / 3600);
      const minutes = Math.floor((startTime % 3600) / 60);
      const seconds = startTime % 60;

      // Если часов нет, выводим только минуты и секунды
      if (hours > 0) {
        return [
          hours.toString().padStart(2, '0'),
          minutes.toString().padStart(2, '0'),
          seconds.toString().padStart(2, '0')
        ].join(':');
      } else {
        return [
          minutes.toString().padStart(2, '0'),
          seconds.toString().padStart(2, '0')
        ].join(':');
      }
    },

    closeFragmentModal() {
      this.showFragmentModal = false;
    },
    goToFragment(url) {
      window.open(url, '_blank');
    }
  }
};
</script>
<style>
  .clear-both{
    clear: both;
  }
</style>
