<template>
  <a name="timecodewords"></a>
  <div class="mt-4 timecode-words">
    <spinner-component :visible="wordsLoading" class="mt-4"></spinner-component>

    <div v-if="!wordsLoading && wordsData.videos">
      <div v-for="(video, video_index) in wordsData.videos" class="video-preview mb-5" :key="video_index">
        <div :data-index="video_index" class="video-header sticky">
          <h2>{{ video.caption }}</h2>
          <div class="d-flex align-items-center">
            <button class="btn btn-sm btn-success me-2" @click="generateVideo(video_index)" v-if="videosHistory.length > 1 && video_index == currentVideoIndex">
              <i class="bi bi-save"></i> Сохранить
            </button>
            <button class="btn btn-sm btn-warning me-2" @click="revertChanges(video_index)" v-if="videosHistory.length > 1 && video_index == currentVideoIndex">
              <i class="bi bi-arrow-counterclockwise"></i> Вернуть
            </button>
            <div v-if="video.segments.length">Продолжительность клипа: {{ calculateTotalDuration(video.segments) }} сек</div>
            <div class="text text-info" v-if="!video.segments.length">⏳ Обрабатывается</div>
          </div>
        </div>

        <div class="row" v-if="video.segments.length">
          <div class="col-lg-3 position-relative">
            <div class="video-container position-relative" :class="!video.video_preview_url ? 'no-preview' : ''" v-if="video.video_preview_url || (video.status === 'generate')">
              <video ref="videoPlayer" :src="video.video_preview_url" controls style="width: 100%;" v-if="video.video_preview_url"></video>
              <div v-if="video.status === 'generate'" class="overlay d-flex justify-content-center align-items-center">
                <div class="c">
                  ⏳
                  <div class="status-caption badge bg-primary d-flex justify-content-center align-items-center">
                    Обрабатывается ~{{ video.generate_remaining_time }}
                  </div>
                </div>
              </div>
            </div>

            <button v-if="!video.video_preview_url && !video.video_final_url && video.status != 'generate'" class="btn btn-sm w-100 btn-primary" @click="generateVideo(video_index, true)">
              Создать превью-видео
            </button>


<settings-panel
  :colors="colors"
  :fonts="fonts"
  :videoId="video.id"
  v-model:selectedColor="video.color_style"
  v-model:selectedAlignment="video.vertical_align"
  v-model:wordsPerLine="video.words_per_line"
  v-model:selectedFont="video.font_name"
  v-model:selectedOrientation="video.orientation"
  v-model:hideSubtitles="video.hide_subtitles"
  v-model:trackFace="video.track_face"
  v-model:selectedFontSize="video.font_size"
  v-model:uppercase="video.uppercase"
  v-model:selectedOutline="video.outline"
  @update="videoSettingsUpdated(video, video_index)"
/>




            <div class="buttons-panel mt-2 mb-4" v-if="!(videosHistory.length > 1 && video_index == currentVideoIndex) && video.video_preview_url">
              <a v-if="video.video_final_url" class="btn btn-sm w-100 btn-primary" :href="video.video_final_url" target="_blank">
                <i class="bi bi-download"></i> Скачать HD
              </a>
              <button v-if="!video.video_final_url && video.status != 'generate'" class="btn btn-sm w-100 btn-success" @click="generateVideo(video_index, false)">
                Получить видео
              </button>
            </div>
          </div>

          <div class="col-lg-9 pb-4">
            <video-tabs v-if="video.score_final > 0" 
              :scores="{
                final: video.score_final,
                hook: video.score_hook,
                flow: video.score_flow,
                engagement: video.score_engagement,
                trend: video.score_trend
              }"
              :video = video 
              :descriptions="{
                hook: video.score_hook_description,
                flow: video.score_flow_description,
                engagement: video.score_engagement_description,
                trend: video.score_trend_description
              }"
            ></video-tabs>

            <button class="btn btn-sm btn-outline-light btn-outline-light-2" @click="fetchSegments({ video, position: 'before', videoIndex: video_index })">
              + Добавить сегмент перед
            </button>

            <draggable
              :list="video.segments"
              class="list-group"
              item-key="start"
              @end="onDragEnd(video_index)"
              handle=".drag-handle"
            >
              <div v-for="(segment, index) in video.segments" :key="segment.start" class="segment">
                <div class="inner d-flex align-items-center" v-if="segment">
                  <div class="drag-handle col-auto d-flex align-items-center me-2">
                    <i class="bi bi-grip-vertical"></i>
                  </div>
                  <div class="inner d-flex align-items-start flex-fill" v-if="segment">
                    <div class="flex-fill">
                      <span
                        v-for="word in segment.words"
                        :key="word.s"
                        class="word"
                        @mousedown="startSelectingWords($event, word, segment, video_index)"
                        @mousemove="selectWords($event, word)"
                        @mouseup="finishSelectingWords($event)"
                        :class="{ 'keyword': word.wk === 'true', 'selected-word': isWordSelected(word) }"
                      >
                        {{ (word.word)?word.word:'&nbsp;' }}
                      </span>
                    </div>
                  </div>

                  <button class="btn btn-sm btn-danger" @click="removeSegment(video_index, index)">
                    <i class="bi bi-trash"></i>
                  </button>
                </div>
              </div>
            </draggable>

            <button class="btn btn-sm btn-outline-light btn-outline-light-2" @click="fetchSegments({ video, position: 'after', videoIndex: video_index })">
              + Добавить сегмент после
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showContextMenu" class="context-menu-overlay" @click="hideContextMenu"></div>

    <div v-if="showContextMenu" class="context-menu" :style="{ top: contextMenuPosition.top + 'px', left: contextMenuPosition.left + 'px' }">
      <ul>
        <li v-if="selectedWords.length === 1 && wordsData.videos[currentVideoIndex].status !== 'generate'" @click="goToSegment(activeWord.start_new)">Перейти к кадру</li>
        <li v-if="selectedWords.length === 1" @click="editWord">Редактировать</li>
        <li v-if="selectedWords.length === 1" @click="toggleWK(activeWord)">{{ activeWord?.wk === 'true' ? 'Убрать ключевое слово' : 'Ключевое слово' }}</li>
        <li @click="removeWordsFromVideo" class="text-danger">Удалить с видео</li>
        <li @click="removeWordText" class="text-danger">Удалить только текст</li>
      </ul>
    </div>

    <div v-if="showEditWordModal" class="modal-overlay" @click="hideEditWordModal"></div>

    <div v-if="showEditWordModal" class="edit-word-modal" :style="{ top: editWordModalPosition.top + 'px', left: editWordModalPosition.left + 'px' }">
      <input
        type="text"
        v-model="editableWord"
        :style="{ width: `calc(${editableWord.length}ch + 10px)` }"
      />
      <button class="btn btn-success" @click="saveEditedWord"><i class="bi bi-check"></i></button>
    </div>

    <div v-if="showSegmentModal" class="modal-overlay">
      <div class="modal-content" ref="segmentModal">
        <div class="modal-header mb-2">
          <div class="d-flex w-100 align-items-center">
            <h3 class="flex-fill m-0">Выберите сегмент для добавления</h3>
            <button class="btn-close" @click="closeSegmentModal"></button>
          </div>
        </div>
        <div class="modal-body">
          <div
            v-for="(segment, index) in localAvailableSegments"
            :key="index"
            :ref="'segment-' + index"
            :class="['segment-option', { selected: segment.selected }]"
            @click="selectSegment(segment, index)"
          >
            <div>{{ segment.text }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import VideoTabs from '@/components/VideoTabsComponent.vue';
import { VueDraggableNext } from 'vue-draggable-next';
import SettingsPanel from '@/components/SettingsPanelComponent.vue';

export default {
  props: {
    materialId: null
  },
  components: {
    SpinnerComponent,
    VideoTabs,
    draggable: VueDraggableNext,
    SettingsPanel
  },
  data() {
    return {
      wordsLoading: false,
      activeWord: null,
      activeSegment: null,
      showContextMenu: false,
      showEditWordModal: false,
      editableWord: '',
      contextMenuPosition: { top: 0, left: 0 },
      editWordModalPosition: { top: 0, left: 0 },
      segmentPosition: null,
      currentVideoIndex: null,
      localAvailableSegments: [],
      selectingWords: false,
      selectedWords: [],
      videosHistory: []
    };
  },

  computed: {
    ...mapGetters('app', ['isMobile', 'configData']),
    ...mapGetters('article', ['getArticleItem', 'getArticleTimecodeWords']),
    ...mapGetters('segment', ['availableSegments', 'showSegmentModal']),
    colors() {
      return this.configData.video_settings.colors;
    },
    fonts() {
      return this.configData.video_settings.fonts;
    },
    articleItem: {
      get() {
        return this.getArticleItem || {};
      },
      set(value) {
        this.updateArticleItem(value);
      },
    },
    wordsData() {
      return this.getArticleTimecodeWords;
    }
  },
  methods: {
    ...mapActions('article', ['fetchArticleTimecodeWords', 'saveMaterialWords', 'saveMaterialVideosSegments', 'generateNewVideo', 'fetchVideoStatus']),
    ...mapActions('segment', ['fetchSegments', 'closeSegmentModal', 'saveSelectedSegment', 'setSegmentInsertIndex', 'resetSegmentInsertIndex']),
    calculateTotalDuration(segments) {
      let totalSeconds = Math.round(segments.reduce((total, segment) => {
        return total + segment.words.reduce((segmentTotal, word) => {
          return segmentTotal + (word.end - word.start);
        }, 0);
      }, 0));

      if (totalSeconds >= 60) {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
      } else {
        return `0:${totalSeconds < 10 ? '0' + totalSeconds : totalSeconds}`;
      }
    },
    showWordMenu(event, word, segment) {
      this.activeWord = word;
      this.activeSegment = segment;
      const rect = event.target.getBoundingClientRect();
      this.contextMenuPosition = { top: rect.bottom + window.scrollY, left: rect.left + window.scrollX };
      this.showContextMenu = true;
    },
    hideContextMenu() {
      this.showContextMenu = false;
      this.activeWord = null;
      this.selectedWords = [];
    },
    editWord(event) {
      this.showContextMenu = false;
      this.editableWord = this.activeWord.word;
      const rect = event.target.getBoundingClientRect();
      const parentRect = event.target.parentElement.getBoundingClientRect();

      this.editWordModalPosition = {
        top: parentRect.top + window.scrollY,
        left: rect.left + window.scrollX
      };

      this.showEditWordModal = true;
    },

    hideEditWordModal() {
      this.showEditWordModal = false;
    },

    saveEditedWord() {
      if (this.activeWord && this.currentVideoIndex !== null) {
        this.saveCurrentState();
        this.activeWord.word = this.editableWord;
        this.showEditWordModal = false;
        this.selectedWords = [];
        this.saveWord(this.activeWord, this.wordsData.videos[this.currentVideoIndex].id, this.activeSegment.start);
      }
    },
    removeWordsFromVideo() {
      this.saveCurrentState();
      this.activeSegment.words = this.activeSegment.words.filter(word => !this.selectedWords.includes(word));
      this.showContextMenu = false;
      this.saveMaterialVideosSegments({
        materialId: this.materialId,
        video: this.wordsData.videos[this.currentVideoIndex]
      });
      this.selectedWords = [];
    },
    removeWordText() {
      this.saveCurrentState();
      this.selectedWords.forEach(word => {
        word.word = '';
        this.saveWord(word, this.wordsData.videos[this.currentVideoIndex].id, this.activeSegment.start);
      });
      this.showContextMenu = false;
      this.selectedWords = [];
    },
    async toggleWK(word) {
      this.saveCurrentState();
      word.wk = word.wk === 'true' ? 'false' : 'true';
      this.showContextMenu = false;
      this.selectedWords = [];
      try {
        await this.saveWord(word, this.wordsData.videos[this.currentVideoIndex].id, this.activeSegment.start);
        console.log('Word saved successfully');
      } catch (error) {
        console.error('Error saving word:', error);
      }
    },
    async saveWord(word, videoId, segmentStart) {
      try {
        await this.saveMaterialWords({
          materialId: this.materialId,
          videoId: videoId,
          segmentStart: segmentStart,
          word
        });
        console.log('Word saved successfully');
      } catch (error) {
        console.error('Error saving word:', error);
      }
    },
    async fetchWords() {
      this.wordsLoading = true;
      try {
        await this.fetchArticleTimecodeWords(this.materialId);
        this.videosHistory.push(JSON.parse(JSON.stringify(this.wordsData.videos)));
        this.wordsData.videos.forEach((video, index) => {
          if (video.status === 'generate') {
            this.pollVideoStatus(index, video.id);
          }
        });
      } catch (error) {
        console.error('Error fetching words:', error);
      } finally {
        this.wordsLoading = false;
      }
    },

async fetchSegments({ video, position, videoIndex }) {
  this.segmentPosition = position;
  this.currentVideoIndex = videoIndex;
  this.$store.commit('segment/resetSegmentInsertIndex');
  const segments = video.segments;
  let insertIndex = 0;

  if (position === 'after') {
    insertIndex = segments.length;
  }

  this.$store.commit('segment/setSegmentInsertIndex', insertIndex);
  await this.$store.dispatch('segment/fetchSegments', { video, position });

  const videoSegments = this.wordsData.videos[videoIndex].segments;
  console.log('videoSegments', videoSegments);

  console.log('Available Segments:', this.availableSegments);

  this.localAvailableSegments = this.availableSegments.map(segment => {
    const segmentStart = segment.words[0]?.start_in_fragment;
    const segmentEnd = segment.words[segment.words.length - 1]?.end_in_fragment;

    console.log(`Segment Start: ${segmentStart}, Segment End: ${segmentEnd}`);
    console.log('Words in Segment:');
    segment.words.forEach(word => {
      console.log(`Word: ${word.word}, Start: ${word.start_in_fragment}, End: ${word.end_in_fragment}`);
    });

    //const isUsed = true;

    const isUsed = videoSegments.some(s => {
      var startMatch = 0;
      var endMatch = 0;
      if (typeof s.words[0]?.start_in_fragment !== 'undefined'){
        startMatch = s.words[0]?.start_in_fragment === segmentStart;
      } else {
        startMatch = s.words[0]?.start === segmentStart;
      }
      if (typeof s.words[0]?.end_in_fragment !== 'undefined'){
        endMatch = s.words[s.words.length - 1]?.end_in_fragment === segmentEnd;
      } else {
        endMatch = s.words[s.words.length - 1]?.end === segmentEnd;
      }
      console.log(`${s.words[0]?.start_in_fragment} = ${segmentStart}`);
      console.log(`${s.words[0]?.start_in_fragment} = ${segmentStart}`);
      console.log(`Checking segment: Start Match: ${startMatch}, End Match: ${endMatch}`);
      return startMatch && endMatch;
    });

    console.log(`Segment isUsed: ${isUsed}`);

    return {
      ...segment,
      selected: isUsed
    };
  });

  this.$nextTick(() => {
    console.log('Local Available Segments:', this.localAvailableSegments);
    const firstSelectedSegmentIndex = this.localAvailableSegments.findIndex(segment => segment.selected);
    console.log('First Selected Segment Index:', firstSelectedSegmentIndex);

    if (firstSelectedSegmentIndex !== -1) {
      const firstSelectedElement = this.$refs[`segment-${firstSelectedSegmentIndex}`][0];
      if (firstSelectedElement) {
        firstSelectedElement.scrollIntoView({ behavior: 'smooth' });
      } else {
        console.log(`Element with index ${firstSelectedSegmentIndex} not found`);
      }
    }
  });
}
,
    async selectSegment(segment, index) {
      this.saveCurrentState();
      const isSelected = this.localAvailableSegments.some((seg, i) =>
        i === index && seg.selected
      );

      if (isSelected) {
        await this.$store.dispatch('segment/removeSelectedSegment', { segment, videoIndex: this.currentVideoIndex });
        this.localAvailableSegments = this.localAvailableSegments.map((seg, i) => {
          if (i === index) {
            return { ...seg, selected: false };
          }
          return seg;
        });
      } else {
        await this.$store.dispatch('segment/saveSelectedSegment', { segment, videoIndex: this.currentVideoIndex });
        this.localAvailableSegments = this.localAvailableSegments.map((seg, i) => {
          if (i === index) {
            return { ...seg, selected: true };
          }
          return seg;
        });
      }


    },
    removeSegment(videoIndex, segmentIndex) {
      this.saveCurrentState();
      this.currentVideoIndex = videoIndex;
      this.wordsData.videos[videoIndex].segments.splice(segmentIndex, 1);
      this.saveMaterialVideosSegments({
        materialId: this.materialId,
        video: this.wordsData.videos[videoIndex]
      });
    },
    onDragEnd(videoIndex) {
      this.currentVideoIndex = videoIndex;
      this.saveCurrentState();
      this.saveMaterialVideosSegments({
        materialId: this.materialId,
        video: this.wordsData.videos[videoIndex]
      });
    },
    startSelectingWords(event, word, segment, videoIndex) {
      this.selectingWords = true;
      this.selectedWords = [word];
      this.activeWord = word;
      this.activeSegment = segment;
      this.currentVideoIndex = videoIndex;
      document.addEventListener('mouseup', this.finishSelectingWords);
    },

    finishSelectingWords(event) {
      this.selectingWords = false;
      document.removeEventListener('mouseup', this.finishSelectingWords);
      if (this.selectedWords.length > 0) {
        if (this.selectedWords.length === 1) {
          const wordElement = event.target;
          const rect = wordElement.getBoundingClientRect();
          this.contextMenuPosition = { top: rect.bottom + window.scrollY, left: rect.left + window.scrollX };
        } else {
          const firstSelectedWordElement = document.querySelector(`.word.selected-word`);
          if (firstSelectedWordElement) {
            const rect = firstSelectedWordElement.getBoundingClientRect();
            this.contextMenuPosition = { top: rect.bottom + window.scrollY, left: rect.left + window.scrollX };
          }
        }
        this.showContextMenu = true;
      }
    },

    selectWords(event, word) {
      if (this.selectingWords && !this.selectedWords.includes(word)) {
        this.selectedWords.push(word);
      }
    },

    isWordSelected(word) {
      return this.selectedWords.includes(word);
    },

    saveCurrentState() {
      //console.log('save saveCurrentState');
      this.videosHistory.push(JSON.parse(JSON.stringify(this.wordsData.videos)));
      if (this.videosHistory.length > 15) {
        this.videosHistory.shift();
      }
    },
    revertChanges(videoIndex) {
      if (this.videosHistory.length > 1) {
        this.videosHistory.pop();
        this.wordsData.videos = JSON.parse(JSON.stringify(this.videosHistory[this.videosHistory.length - 1]));
        this.saveMaterialVideosSegments({
          materialId: this.materialId,
          video: this.wordsData.videos[videoIndex]
        });
      }
    },
    goToSegment(start) {
      console.log(this.currentVideoIndex, start);
      const videoData = this.wordsData.videos[this.currentVideoIndex];
      if (videoData.status == 'generate') return false;
      const videoPlayer = this.$refs.videoPlayer[this.currentVideoIndex];
      if (videoPlayer) {
        videoPlayer.currentTime = start;
        videoPlayer.play();
      }
      this.showContextMenu = false;
    },

    videoSettingsUpdated(video, video_index) {
      this.currentVideoIndex = video_index;
      console.log(video);
      this.saveMaterialVideosSegments({
        materialId: this.materialId,
        video: this.wordsData.videos[video_index]
      });
      this.saveCurrentState();
    },
    async generateVideo(videoIndex, preview_mode = true) {
      try {
        const videoData = this.wordsData.videos[videoIndex];
        this.videosHistory = [];
        this.saveCurrentState();
        const response = await this.generateNewVideo({
          materialId: this.materialId,
          videoId: videoData.id,
          preview_mode: preview_mode,
          video: videoData
        });

        console.log(response);
        this.wordsData.videos[videoIndex] = response.video;
        console.log('New video generated successfully');
        this.pollVideoStatus(videoIndex, response.video.id);
      } catch (error) {
        console.error('Error generating new video:', error);
      }
    },

    async pollVideoStatus(videoIndex, videoId) {
      console.log('pollVideoStatus');
      try {
        const intervalId = setInterval(async () => {
          const response = await this.fetchVideoStatus({
            materialId: this.materialId,
            videoId: videoId
          });

          console.log(response);
          this.wordsData.videos[videoIndex].generate_remaining_time = response.video.generate_remaining_time;
          if (response.video.status !== 'generate') {
            clearInterval(intervalId);
            this.wordsData.videos[videoIndex] = response.video;
            console.log('Video status updated successfully');
          }
        }, 10000);
      } catch (error) {
        console.error('Error polling video status:', error);
      }
    }
  },
  mounted() {
    this.fetchWords();
  }
};
</script>


<style>
.segment {
  opacity: 1;
}

.segment .inner {
  padding: 3px 0;
}

.segment.selected {
  border-radius: 8px;
  background: #78ff7926;
  opacity: 1;
}

.segment.hidden {
  display: none;
}

.word {
  display: inline-block;
  margin-right: 8px;
  cursor: pointer;
}

.word.keyword {
  color: #b79dff;
}

.word.selected-word {
  background: #0087ff;
  padding: 0 0;
}

.context-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 999;
}

.context-menu {
  position: absolute;
  background: #030303;
  border-radius: 6px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.context-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.context-menu li {
  padding: 8px 12px;
  cursor: pointer;
}

.context-menu li:hover {
  background: #333333;
}

.edit-word-modal {
  position: absolute;
  background: #0c0f12;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
  display: flex;
  gap: 8px;
}
.edit-word-modal input {
  min-width: 40px;
  border-radius: 6px;
  border: 1px solid #3e3939;
  background: #333;
}

.modal-overlay {
  position: fixed;
  top: 0;
  z-index: 2;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview .modal-content {
  background: #212529;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.preview .modal-body {
  overflow-y: auto;
  flex-grow: 1;
  height: 80vh;
}

.segment-option {
  cursor: pointer;
  padding: 10px;
  opacity: 0.3;
  margin-bottom: 10px;
  border-radius: 4px;
}

.segment-option:hover {
  background: #121619;
}

.segment-option.selected {
  background: #121619;
  opacity: 1;
}

.video-preview {
  user-select: none;
}

.video-preview .video-container {
  border-radius: 10px;
  display: flex;
  overflow: hidden;
  align-items: center;
  min-height: 150px;
}

.video-preview .video-container.no-preview {
}

.video-preview .video-container video {
  min-height: 150px;
  max-height: 200px;
}

@media (max-width: 992px) {
  .video-preview .video-container video {
  }
}

@media (max-width: 767px) {
  .video-preview .video-container video {
  }
}

@media (max-width: 767px) {
  .modal-content {
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
    border-radius: 0;
  }
}

.video-header.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #212529;
  padding-bottom: 10px;
}

.overlay {
  position: absolute;
  border-radius: 10px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 4rem;
  z-index: 0;
}

.overlay .c {
  text-align: center;
}

.overlay .status-caption {
  font-size: 0.8rem;
}

.status-overlay {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 0;
}

.color-box {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 4px;
}

.color-box.selected {
  border: 2px solid #000;
}
</style>
