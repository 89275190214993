import { createRouter, createWebHistory } from 'vue-router';
import MaterialsComponent from '@/components/MaterialsComponent.vue';
import CollectionsComponent from '@/components/CollectionsComponent.vue';
import CollectionComponent from '@/components/CollectionComponent.vue';
import ChannelsComponent from '@/components/ChannelsComponent.vue';
import ChannelComponent from '@/components/ChannelComponent.vue';
import ArticleComponent from '@/components/ArticleComponent.vue';
import MainComponent from '@/components/MainComponent.vue';
import SearchComponent from '@/components/SearchComponent.vue';
import AboutComponent from '@/components/AboutComponent.vue';

const routes = [
  // ...существующие маршруты
  {
    path: '/',
    name: 'Main',
    component: MainComponent,
    meta: {
      title: 'Главная' // Set the title for this route
    }    
  },

  {
    path: '/about',
    name: 'About',
    component: AboutComponent,
    meta: {
      title: 'О проекте' // Set the title for this route
    }    
  },

  {
    path: '/materials',
    name: 'Materials',
    component: MaterialsComponent,
    meta: {
      title: 'Материалы' // Set the title for this route
    }    
  },

  {
    path: '/collections',
    name: 'Collections',
    component: CollectionsComponent,
    meta: {
      title: 'Курсы и плейлисты' // Set the title for this route
    }    
  },
  {
    path: '/collection/:id', // Замените ":id" на параметр, который будет идентифицировать статью
    name: 'Collection',
    component: CollectionComponent,
    meta: {
      title: 'Информация о курсе или плейлисте' // Set the title for this route
    }    
  },
  {
    path: '/channels',
    name: 'Channels',
    component: ChannelsComponent,
    meta: {
      title: 'Каналы' // Set the title for this route
    }        
  },
  {
    path: '/channel/:id', // Замените ":id" на параметр, который будет идентифицировать статью
    name: 'Channel',
    component: ChannelComponent,
    meta: {
      title: 'Информация о канале' // Set the title for this route
    }    
  },
  {
    path: '/article/:id', // Замените ":id" на параметр, который будет идентифицировать статью
    name: 'Article',
    component: ArticleComponent,
    meta: {
      title: 'Материал' // Set the title for this route
    }    
  },
  {
    path: '/search/:query/:type/:contextId?',
    name: 'Search',
    component: SearchComponent,
    props: (route) => ({
      query: route.params.query,
      type: route.params.type,
      contextId: route.params.contextId || null 
    })
  }
];

const router = createRouter({
  history: createWebHistory('/app/'),
  routes,
});

export default router;
