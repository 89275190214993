<template>
  <div>
    <!-- Кнопка-гамбургер, которая показывается, когда меню свёрнуто -->
    <button v-if="!menuExpanded" @click="toggleMenu" class="hamburger-btn">
      <i class="bi bi-list"></i>
    </button>

    <!-- Боковое меню с анимацией -->
    <transition name="slide" v-if="configData && configData.menu">
      <div class="side-menu" v-show="menuExpanded">
        <router-link to="/" class="menu-item"  v-if="configData.logo_thumbs">
          <img :src="configData.logo_thumbs.middle" class="w-100" alt="" />
        </router-link>

        <button @click="toggleMenu" class="collapse-menu-btn">
          <i class="bi bi-chevron-left"></i> 
        </button>


         <router-link to="/" class="menu-item" v-if="configData.menu.main">
          <i class="bi bi-house-door"></i>
          <span>Главная</span>
        </router-link>


        <div v-if="!configData.block_data_management">
          <router-link to="/channels" class="menu-item"  v-if="configData.menu.channels">
            <i class="bi bi-collection"></i>
            <span>{{configData.menu.channels.caption}}</span>
          </router-link>

          <router-link to="/collections" class="menu-item" v-if="configData.menu.collections">
            <i class="bi bi-boxes"></i>
            <span>{{configData.menu.collections.caption}}</span>
          </router-link>

          <router-link to="/materials" class="menu-item" v-if="configData.menu.materials">
            <i class="bi bi-journal"></i>
            <span>{{configData.menu.materials.caption}}</span>
          </router-link>
        </div>

        <telegram-auth class="mb-3" v-if="!configData.disable_telegram_auth"></telegram-auth>

      </div>
    </transition>

    <overlay-component :visible="menuExpanded && isMobile" @click="toggleMenu"></overlay-component>
  </div>
</template>


<script>
import { computed, nextTick } from 'vue';
import { useStore } from 'vuex';
import { mapGetters, mapActions } from 'vuex';
import OverlayComponent from '@/components/OverlayComponent.vue';
import useClipboard from 'vue-clipboard3'
import { useToast } from "vue-toastification";
import TelegramAuth from './TelegramAuth.vue';
import { useRoute } from 'vue-router';

export default {
  data(){
    return{
      menuExpanded: false, // Управляет состоянием меню
    }
  },

  components: {
    OverlayComponent,
    TelegramAuth
  },  
  methods: {
    ...mapActions('app', ['setIsMobile']),

    toggleMenu() {
      this.menuExpanded = !this.menuExpanded; // Переключаем состояние меню
      this.updateBodyClass(); // Обновляем класс тега body
    },
    
    updateBodyClass() {
      const body = document.body;
      if (this.menuExpanded) {
        body.classList.add('menu-open');
        body.classList.remove('menu-closed');


      } else {
        body.classList.add('menu-closed');
        body.classList.remove('menu-open');
      }
    }
  },
  mounted() {
    // Устанавливаем класс при монтировании компонента

    nextTick(() => {
      console.log('isMobile ', this.isMobile);
      if (this.isMobile){
        this.menuExpanded = false;
        console.log(this.menuExpanded);
      } else {
        this.menuExpanded = true;
      }

      this.updateBodyClass();

    });
  },
  computed: {
    ...mapGetters('app', ['isMobile', 'configData']), 
    ...mapGetters("article", ["getArticleItem"]),
    ...mapGetters("channel", ["getChannelItem"]),
    ...mapGetters("collection", ["getCollectionItem"]),

    articleItem: {
      get() {
        return this.getArticleItem || {};
      },
      set(value) {
        this.updateArticleItem(value);
      },
    },
    channelItem: {
      get() {
        return this.getChannelItem || {};
      },
      set(value) {
        this.updateChannelItem(value);
      },
    },
    collectionItem: {
      get() {
        return this.getCollectionItem || {};
      },
      set(value) {
        this.updateCollectionItem(value);
      },
    }
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const article = computed(() => store.getters['article/getArticleItem']);
    const collection = computed(() => store.getters['collection/getCollectionItem']);
    const isAuthenticated = computed(() => store.getters['user/isAuthenticated']);

    const { toClipboard } = useClipboard()

    const copy = async (text) => {
      try {
        await toClipboard(text)
        const toast = useToast();
        toast.success("Скопировано в буфер обмена", { timeout: 2000 });
      } catch (e) {
        console.error(e)
      }
    }

    return {
      article, collection, copy, isAuthenticated, route
    };
  } 
};
</script>

<style scoped>
/* Боковое меню */
.side-menu {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 250px;
  background-color: #f8f9fa;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: transform 0.3s ease;
}

/* Стиль для пунктов меню */
.side-menu .menu-item {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  margin-bottom: 10px;
  color: #333;
  font-weight: 500;
  text-decoration: none;
  background-color: #fff;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
}

/* Иконки рядом с текстом в меню */
.side-menu .menu-item i {
  font-size: 20px;
  margin-right: 10px;
  color: #6c757d; /* Цвет иконок */
}

/* Текст в меню */
.side-menu .menu-item span {
  font-size: 16px;
}

/* Эффект при наведении */
.side-menu .menu-item:hover {
  background-color: #e7f3ff; /* Светло-голубой цвет при наведении */
  color: #007bff; /* Цвет текста при наведении */
}

/* Активный элемент меню */
.side-menu .menu-item-active {
  background-color: #e0f0ff;
  color: #007bff;
}

/* Кнопка для открытия меню (гамбургер) */
.hamburger-btn {
  position: fixed;
  top: 10px;
  left: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

/* Кнопка для закрытия меню */
.collapse-menu-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}
/* Анимация для бокового меню */
.slide-enter-active, .slide-leave-active {
  transition: transform 0.3s ease;
}

.slide-enter, .slide-leave-to {
  transform: translateX(-100%);
}

.side-menu .telegram-auth{
  display: flex;
  align-items: center;
  padding: 10px 15px;
  justify-content: center;
  margin-bottom: 10px;
  color: #333;
  font-weight: 500;
  text-decoration: none;
  background-color: #fff;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
}

</style>
