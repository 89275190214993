<template>
  <div class="card mb-3">
    <div class="card-body">
      <h4 class="card-title">О проекте</h4>
      <div class="card-text">
        <p>«Хари Катха» — это платформа с лекциями проповедников бхакти-йоги, дополненными конспектами и тестами для лучшего усвоения знаний. </p>
      </div>
      <button class="btn btn-primary w-100" @click="openModal">Миссия проекта</button>
    </div>

    <div class="modal" tabindex="-1" role="dialog" :class="{ 'd-block': showModal }">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Миссия и цель проекта «Хари Катха»</h5>
            <button type="button" class="btn btn-light" @click="closeModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>«Хари Катха» — это место, где мы собираем вдохновляющие лекции проповедников традиции бхакти-йоги, чтобы помочь вам углубить свои знания. </p>
            <p>Мы не просто предлагаем видео — каждую лекцию сопровождают текстовые конспекты и выжимки, а тесты для самопроверки помогут закрепить понимание. Всё сделано для того, чтобы ваше духовное обучение было глубоким, системным и удобным.</p>

            <p>
              <b>Платформа «Хари Катха» предлагает:</b>
              <ul>
                <li><b>Удобный глобальный поиск по темам</b> — найдите, где в лекциях разных учителей упоминаются интересующие вас вопросы.</li>
                <li><b>Текстовые конспекты и выжимки</b> — ключевые моменты лекций для быстрого восприятия информации.</li>
                <li><b>Тесты для самопроверки</b> — чтобы вы могли убедиться в усвоении материала.</li>
                <li><b>Лекции различных проповедников</b>, представленных на одной платформе, с возможностью предлагать новых учителей.</li>
              </ul>
            </p>
            <blockquote>
              Наша цель — сделать духовное обучение доступным и упорядоченным, а платформа «Хари Катха» станет вашим надежным спутником на пути к глубокому пониманию бхакти-йоги.
            </blockquote>

            <hr/>
            <div class="small" v-if="configData.show_donation_info">
              <h6>Поддержка проекта</h6>
              <p>
                Вы можете поддержать развитие платформы «Хари Катха», чтобы мы могли быстрее добавлять новые лекции, тесты, конспекты, а также расширить библиотеку новыми учителями. 
              </p>
              <div class="alert alert-info">
                <h5>Реквизиты для поддержки</h5>
                <p class="">Тинькофф: <button class="btn btn-light" @click="copy('5536 9137 6657 9771')">5536 9137 6657 9771</button></p>
                <p class="">Газпром Банк: <button class="btn btn-light" @click="copy('6233 7201 7330 3628')">6233 7201 7330 3628</button></p>
                <p class="">СберБанк: <button class="btn btn-light" @click="copy('5469 1100 1528 2353')">5469 1100 1528 2353</button></p>
                <p>Получатель: Ярмоц Алексей</p>
                <p>Назначение платежа: Дарение</p>
              </div>
              <p>
                Мы искренне благодарим вас за вашу поддержку и вклад в развитие проекта «Хари Катха». Благодаря вам мы сможем продолжать делать духовное обучение доступным и удобным для всех.
              </p>
            </div>


          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal">Закрыть</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <overlay-component :visible="showModal"></overlay-component>
</template>

<script>
import OverlayComponent from '@/components/OverlayComponent.vue';
import useClipboard from 'vue-clipboard3'
import { useToast } from "vue-toastification";
import {  mapGetters} from 'vuex';


export default {
  data() {
    return {
      showModal: false
    };
  },
  components: {
    OverlayComponent
  },
  computed: {
  ...mapGetters('app', ['isMobile', 'configData', 'isPreview'])
  },

  setup() {
    const { toClipboard } = useClipboard()

    const copy = async (text) => {
      try {
        await toClipboard(text)
        //console.log('Copied to clipboard')
        const toast = useToast();

        // or with options
        toast.success("Скопировано в буфер обмена", {
          timeout: 2000
        });
      } catch (e) {
        console.error(e)
      }
    }

    return { copy }
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  }
};
</script>
