<template>
  <div class="pt-4">
  
  
  <div class="shadow-sm rounded py-3 ps-4 pe-2 mt-2" v-if="!isAuthenticated">  
    <h1>Мои каналы</h1>
    <p>Войдите в личный кабинет, чтобы продолжить.</p>
    <telegram-auth v-if="!configData.disable_telegram_auth"></telegram-auth>
  </div>
  
  <div class="pt-4" v-if="isAuthenticated">
    <h1>Мои каналы</h1>
    <div>
      <button @click="showAddEditChannelModal" class="btn btn-primary">Создать канал</button>
      <a :href="configData.help_videos.channels.url" v-if="configData.help_videos" target="_blank" class="btn btn-light ms-2"><i class="bi bi-question-circle"></i> Инструкция</a>
    </div>


    <spinner-component :visible="listLoading" class="mt-4"></spinner-component>

    <ul class="list-group mt-3">
      <li v-for="(channel) in channels" :key="channel.id" class="list-group-item d-flex justify-content-between align-items-start">
        <div class="d-flex align-items-start">
          <div class="" v-if="channel.logo_square_thumbs && channel.logo_square_thumbs.middle"><img :src="channel.logo_square_thumbs.middle" alt="" class="thumb me-2"></div>
          <div class="thumb empty-thumb" v-if="!channel.logo_square_thumbs || !channel.logo_square_thumbs.middle"><i class="bi bi-camera"></i></div>
          <div>
            <h4 class="mb-0"> <router-link :to="{ name: 'Channel', params: { id: channel.hash } }">
                  {{ channel.caption }}
                </router-link></h4>
            
            <!-- <p v-if="channel.materials_count" class="mb-0">{{ channel.materials_count }} материалов</p> -->
            <p class="mb-0" v-html="channel.description"></p>
          </div>
        </div>
        <div class="channel-actions text-nowrap">
          <button @click="showAddEditChannelModal(channel)" class="btn btn-primary">
            <i class="bi bi-pencil"></i>
          </button>
          <button @click="confirmDelete(channel)" class="btn btn-danger ms-2">
            <i class="bi bi-trash"></i>
          </button>
        </div>
      </li>
    </ul>

    <!-- <div class="mt-3 alert alert-info">
      <p>Добавить новые материалы вы можете через чат-бот. Просто добавьте видео или аудио на преобразование в текст. После обработки тут вы сможете отредактировать текст материала, а также тест к нему при необходимости.</p>
      <a class="btn btn-primary" href="https://t.me/vid2text_bot">Перейти в чат-бот</a>
    </div> -->

      <!-- Modal window for creating a channel -->
      <div class="modal" tabindex="-1" role="dialog" :class="{ 'd-block': showCreateModal }">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h2 class="modal-title">{{ channelItem.id ? 'Редактировать канал' : 'Создать канал' }}</h2>
              <button type="button" class="btn btn-light" @click="cancelChannel">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <ul class="nav nav-tabs mb-4" v-if="channelItem.id">
                <li class="nav-item">
                  <button class="nav-link" :class="{ active: activeTab === 'settings' }" @click="activeTab = 'settings'">Настройки</button>
                </li>              
                
                <!-- <li class="nav-item">
                  <button class="nav-link" :class="{ active: activeTab === 'materials' }" @click="activeTab = 'materials'">Материалы</button>
                </li> -->

              </ul>
  <!--             <div v-if="activeTab === 'materials'" class="tab-content">

                <MaterialSelectorComponent :channelId="channelItem.id"/>
              </div> -->

              <div v-if="activeTab === 'settings'" class="tab-content">
                <form>
                  <div class="form-group mb-2">
                    <label for="title">Название</label>
                    <input type="text" id="caption" class="form-control" v-model="channelItem.caption" required>
                  </div>
                  <div class="form-group mb-2">
                    <label for="description">Описание</label>
                    <!-- <textarea id="description" class="form-control" v-model="channelItem.description"></textarea> -->
                    <div  class="editor-container">
                    <QuillEditor
                      theme="snow"
                      v-model:content="channelItem.description"
                      contentType="html"
                      :toolbar="[
                        { 'header': [2, 3, 4, false] },
                        'bold',
                        'italic',
                        'underline',
                        'blockquote',
                        { list: 'ordered' },
                        { list: 'bullet' },
                        'clean',
                        'html'
                      ]"
                    />
                    </div>

                  </div>

                  <div v-if="channelItem.id">
                    <div class="form-group mb-2" v-if="channelItem.thumbs && channelItem.thumbs.big">
                      <label for="coverImage">Обложка</label>
                      <div><img :src="channelItem.thumbs.big" class="cover"/></div>
                    </div>


                    <spinner-component :visible="coverUploading"></spinner-component>
                    <div class="form-group mb-2">
                      <label for="coverImage">Загрузить обложку (рекомендуемый размер 1920x1080)</label>
                      <div><input type="file" id="coverImage" @change="handleCoverImageChange" accept="image/*"></div>
                    </div>


                    <spinner-component :visible="logoSquareUploading"></spinner-component>

                    <div class="form-group mb-2" v-if="channelItem.logo_square_thumbs && channelItem.logo_square_thumbs.big">
                      <label for="logoSquare">Лого (квадратное, 512x512 пикселей)</label>
                      <div><img :src="channelItem.logo_square_thumbs.big" class="logo-square-img"/></div>
                    </div>

                    <div class="form-group mb-2">
                      <label for="logoSquare">Загрузить лого (квадратное)</label>
                      <div><input type="file" id="logoSquare" @change="handleLogoChange($event, 'logo_square')" accept="image/*"></div>
                    </div>

                    <spinner-component :visible="logoUploading"></spinner-component>

                    <div class="form-group mb-2" v-if="channelItem.logo_thumbs && channelItem.logo_thumbs.big">
                      <label for="logoSquare">Лого (горизонтальное, 600x200 пикселей),</label>
                      <div><img :src="channelItem.logo_thumbs.big" class="logo-img"/></div>
                    </div>

                    <div class="form-group mb-2">
                      <label for="logo">Загрузить лого (горизонтальное)</label>
                      <div><input type="file" id="logo" @change="handleLogoChange($event, 'logo')" accept="image/*"></div>
                    </div>
                  </div>




                  <div class="mt-1 d-flex justify-content-between align-items-center">
                    <button type="button" class="btn btn-primary" @click="saveChannel">{{ channelItem.id ? 'Сохранить' : 'Создать' }}</button>
                    <button type="button" class="btn btn-secondary" @click="cancelChannel">
                    {{ editingChannelId ? 'Отмена' : 'Закрыть' }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <overlay-component :visible="showCreateModal"></overlay-component>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters, mapMutations, useStore } from 'vuex';
import { computed } from 'vue';
import { useToast } from "vue-toastification";
import OverlayComponent from '@/components/OverlayComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import TelegramAuth from './TelegramAuth.vue';

//import MaterialSelectorComponent from '@/components/MaterialSelectorComponent.vue';
import { QuillEditor } from '@vueup/vue-quill'

export default {
  data() {
    return {
      editingChannelId: null,
      activeTab: 'settings',
      showCreateModal: false,
      coverUploading: false,
      logoUploading: false,
      logoSquareUploading: false,
      listLoading: false,

    };
  },
  components: {
    OverlayComponent,
    SpinnerComponent,
    TelegramAuth,
    QuillEditor,
    //MaterialSelectorComponent
  },
  computed: {
    ...mapGetters('app', ['isMobile', 'configData', 'chatbotName']),    
    ...mapGetters('channel', ['getChannelItem', 'getChannels']),
    channelItem: {
      get() {
        return this.getChannelItem || {};
      },
      set(value) {
        this.updateChannelItem(value);
      },
    },
    channels: {
      get() {
        return this.getChannels || [];
      },
      set(value) {
        this.$store.commit('channel/setChannels', value);
      },
    }

  },  
  setup() {
    const store = useStore();
    const isAuthenticated = computed(() => store.getters['user/isAuthenticated']);
    return {
      isAuthenticated,
    };
  },  
  created() {
    // Вызов экшена для загрузки подборки
    this.fetchChannels();
  },  
  methods: {
    ...mapMutations('channel', ['setChannelItem', 'setChannels']),
    ...mapActions('channel', ['fetchChannel', 'saveChannel', 'deleteChannel', 'editMaterial', 'deleteMaterial', 'getMaterialsForChannel', 'fetchChannels', 'saveChannelCoverImage', 'saveChannelLogo']),
    // editChannel(channel) {
    //   // Установка ID редактируемой подборки и заполнение полей формы данными
    //   this.editingChannelId = channel.id;

    //   // Вызов мутации `setChannelItem` из модуля `channel`
    //   this.$store.commit('channel/setChannelItem', channel);

    //   // Отображение модального окна для редактирования подборки
    //   this.showCreateModal = true;
    // },

    editChannel(channel) {
      // Установка ID редактируемой подборки и заполнение полей формы данными
      this.editingChannelId = channel.id;

      // Вызов мутации `setChannelItem` из модуля `channel`
      this.setChannelItem(channel);

      // Отображение модального окна для редактирования подборки
      this.showCreateModal = true;
    },

    confirmDelete(channel) {
      if (window.confirm('Подтверждаете удаление?')) {
        this.deleteChannel(channel);
      }
    },

    async handleLogoChange(event, type) {
      const file = event.target.files[0];
      //console.log(file);
      if (!file) return; // Если файл не выбран, ничего не делаем

      if (type == 'logo'){
        this.logoUploading = true;
      } else {
        this.logoSquareUploading = true;
      }
      // Подготавливаем файл для загрузки с помощью FormData
      const formData = new FormData();
      formData.append('logoImage', file);
      //console.log(formData);

      console.log(this.channelItem);
      try {
        // Вызываем экшен для сохранения обложки на сервере
        const response = await this.saveChannelLogo({
          channelId: this.channelItem.id,
          logoForm: formData,
          type: type
        });

        this.setChannelItem(response.channel);
        // Здесь можно добавить обработку успешного сохранения обложки, если необходимо
        console.log(response);
        if (type == 'logo'){
          this.logoUploading = false;
        } else {
          this.logoSquareUploading = false;
        }

      } catch (error) {
        if (type == 'logo'){
          this.logoUploading = false;
        } else {
          this.logoSquareUploading = false;
        }

        // Обработка ошибки, если произошла ошибка при сохранении обложки
        console.error('Error uploading cover image:', error);
      }
    },


    async handleCoverImageChange(event) {
      const file = event.target.files[0];
      //console.log(file);
      if (!file) return; // Если файл не выбран, ничего не делаем

      this.coverUploading = true;
      // Подготавливаем файл для загрузки с помощью FormData
      const formData = new FormData();
      formData.append('coverImage', file);
      //console.log(formData);

      console.log(this.channelItem);
      try {
        // Вызываем экшен для сохранения обложки на сервере
        const response = await this.saveChannelCoverImage({
          channelId: this.channelItem.id,
          coverImageForm: formData
        });

        this.setChannelItem(response.channel);
        this.coverUploading = false;
        // Здесь можно добавить обработку успешного сохранения обложки, если необходимо
        console.log(response);
      } catch (error) {
        this.coverUploading = false;
        // Обработка ошибки, если произошла ошибка при сохранении обложки
        console.error('Error uploading cover image:', error);
      }
    },

    deleteChannel(channel) {
      this.setChannelItem(channel);
      this.$store.dispatch('channel/deleteChannel', channel)
        .then((data) => {
          console.log(data);
          const toast = useToast();
          toast.success('Канал удален', {
            timeout: 2000,
          });

          // Найти и удалить коллекцию из массива channels
          const index = this.channels.findIndex(coll => coll.id === channel.id);
          if (index !== -1) {
            this.channels.splice(index, 1);
          }

          // Обновление текущей редактируемой подборки, если она совпадает с удаляемой
          if (this.channelItem && this.channelItem.id === channel.id) {
            this.setChannelItem(null);
          }
        })
        .catch((error) => {
          console.log(error);
          // Обработка ошибок при удалении
        });

      console.log('Channel deleted successfully');
    },


    fetchChannels() {
      this.listLoading = true;

      this.setChannels([]);

      this.$store.dispatch('channel/fetchChannels')
        .then((data) => {
          console.log(data);
          this.setChannels(data.channels);
          this.listLoading = false;

          // Обработка успешной загрузки списка подборок
          //this.channels = data.list;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
          // Обработка ошибок при загрузке списка подборок
        });
    },

    showAddEditChannelModal(channel = null) {
      // Reset the form fields
      //this.channelForm.title = '';
      //this.channelForm.description = '';

      this.activeTab = 'settings';
      // Check if channel parameter is provided for editing
      if (channel) {
         this.setChannelItem(channel);
        //this.channelItem.title = channel.title;
        //this.channelForm.description = channel.description;
        //this.editingChannelId = channel.id; // Set the editingChannelId to the channel id
      } else {
        this.editingChannelId = null; // Reset the editingChannelId if creating a new channel
      }

      this.$store.dispatch('material/clearChannelMaterials');

      // Show the modal window
      this.showCreateModal = true;
    },
    
    async saveChannel() {
      
      const addNew = (typeof this.channelItem.id == 'undefined')?true:false;
      const updatedChannel = {
        id: this.channelItem.id,
        caption: this.channelItem.caption,
        description: this.channelItem.description,
      };

            
      try {
          const data = await this.$store.dispatch('channel/saveChannel', updatedChannel);
          console.log(data);
          const toast = useToast();
          toast.success('Канал сохранен', {
            timeout: 2000,
          });


          // Найти и обновить коллекцию в массиве channels
          let index = this.channels.findIndex(channel => channel.id === this.channelItem.id);
          if (index !== -1) {
            this.channels[index] = this.channelItem;
          }

          if (updatedChannel.id){
            this.cancelChannel();
          }

          if (addNew) this.channels.unshift(this.channelItem);
      } catch (error) {
        console.log(error);
        // Обработка ошибок при сохранении
      }

    },



    cancelChannel() {
      this.showCreateModal = false;
      this.setChannelItem(null); // Обновление связанного объекта channelItem
    },

    selectFromMyMaterials() {
      // Perform the action when selecting from my materials
    }
  }
};
</script>

<style>
/* Component styles here */
.cover{
  width: 300px;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
}

.thumb{
  width: 60px;
  min-width: 60px;
  height: 60px;
  border-radius: 8px;
  margin-right: 6px;
}

.logo-img{
  width: 300px;
  height: auto;
  border-radius: 8px;
  margin-right: 6px;
}

.logo-square-img{
  width: 250px;
  height: 250px;
  border-radius: 8px;
  margin-right: 6px;
}


.empty-thumb{
  background-color: #EFEFEF;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
</style>
