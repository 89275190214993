<template>
  <div v-if="shloka" class="shloka-container">
    <h5><a :href="shloka.link" target="_blank">{{shloka.scripture_title_short}} {{shloka.number}}</a></h5>
    <div v-html="shloka.cyrillic" class="cyrillic"></div>
    
    <div class="translation">{{ shloka.translation }}</div>
  </div>
  <div v-else>
    <p>Загрузка шлоки...</p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    alias: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters('shloka', ['getShlokaItem']),
    shloka() {
      return this.getShlokaItem;
    }
  },

  watch: {
    alias: {
      immediate: true,
      handler(newAlias) {
        this.loadShloka(newAlias);
      }
    }
  },
  created() {
    this.loadShloka(this.alias);
  },
  methods: {
    ...mapActions('shloka', ['fetchShloka']),
    loadShloka(alias) {
      this.fetchShloka(alias)
        .then(() => {
          console.log('Шлока успешно загружена');
        })
        .catch(error => {
          console.error('Ошибка при загрузке шлоки:', error);
        });
    }
  }
};
</script>


<style scoped>
.shloka-container{
  padding: 20px;
  background-color: #f1ecff;
  margin: 10px 0;
  border-radius: 6px;
}

.shloka-container h5{
  text-align: center;
}

.shloka-container .cyrillic{
  text-align: center;
  font-style: italic;
  font-size: 1.2em;
}

.shloka-container .translation{
  text-align: center;
  padding-top: 10px;
  border-top: 1px solid #d8caff;
  margin-top: 10px;
}

.loading {
  font-style: italic;
  color: #999;
}
</style>
