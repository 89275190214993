<template>
  <div class="modal" tabindex="-1" role="dialog" v-if="show">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Сообщение об ошибке</h5>
          <button type="button" class="close" @click="closeModal">
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Выделенный текст:</p>
          <p>{{ selectedText }}</p>
          <textarea v-model="errorMessage" placeholder="Опишите ошибку..." class="form-control"></textarea>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">Закрыть</button>
          <button type="button" class="btn btn-primary" @click="sendErrorMessage">Отправить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedText: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      errorMessage: '',
    };
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    sendErrorMessage() {
      console.log('Ошибка отправлена:', this.errorMessage);
      // Здесь добавьте логику отправки сообщения, например, через API
      this.closeModal();
    },
  },
};
</script>
