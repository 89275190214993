import Quill from 'quill';
let Inline = Quill.import('blots/inline');

class TimecodeBlot extends Inline {
  static create(value) {
    let node = super.create();
    // Обрабатываем атрибуты id и starttime
    node.setAttribute('id', value.id);
    node.setAttribute('starttime', value.starttime);
    node.innerText = `⏲ Timecode: ${value.starttime}`;
    return node;
  }

  static formats(node) {
    return {
      id: node.getAttribute('id'),
      starttime: node.getAttribute('starttime'),
    };
  }

  format(name, value) {
    if (name === 'timecode' && value) {
      this.domNode.setAttribute('id', value.id);
      this.domNode.setAttribute('starttime', value.starttime);
      this.domNode.innerText = `⏲ Timecode: ${value.starttime}`;
    } else {
      super.format(name, value);
    }
  }
}

TimecodeBlot.blotName = 'timecode';
TimecodeBlot.tagName = 'timecode'; // Указываем, что блот соответствует тегу <timecode>

export default TimecodeBlot;
