<template>

  <div class="main-component bg-white shadow-sm rounded py-3 ps-4 pe-2 mt-2" v-if="configData && configData.bot_username == 'bystrokurs_bot'">
    <h1>Быстрокурс</h1>
    <p>Платформа для создания и проведения быстрокурсов в Telegram c бесплатным хранилищем и защитой контента.</p>

    <p>Посмотрите короткое видео, чтобы узнать, как создавать курсы в платформе «Быстрокурс» с автоматическим созданием контента из видео и настройкой приема платежей.</p>
    <div>
      <a href="https://www.youtube.com/playlist?list=PL-jGSgsIvr_p_YLcJ6F0M0vn_5rIH0u0r" class="btn btn-primary mt-2" target="_blank">Смотреть видео-инструкцию</a> 
      <a href="https://t.me/ayarmots" class="btn btn-light mt-2 ms-2" target="_blank">Написать разработчику</a> 
    </div>

    <p v-if="!isAuthenticated">Войдите, чтобы начать пользоваться платформой.</p>


    <telegram-auth v-if="!isAuthenticated && !configData.disable_telegram_auth"></telegram-auth>

    <div v-if="isAuthenticated">
      <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 mt-3">
        <!-- Loop for existing channels -->
        <div v-for="channel in channels" :key="channel.id" class="col">
          <div class="card h-100">
            <img :src="channel.logo_square_thumbs.middle" class="card-img-top img-fluid" :alt="channel.caption" v-if="channel.logo_square_thumbs.middle">
            <img :src="channelCoverUrl" class="card-img-top img-fluid" :alt="channel.caption" v-if="!channel.logo_square_thumbs.middle">

            <div class="card-body d-flex flex-column">
              <h5 class="card-title">{{ channel.caption }}</h5>

              <a :href="channel.link" class="btn btn btn-primary">Открыть</a>

              <!-- You can add more information about the channel here -->
              <!-- For example: -->
              <!-- <p class="card-text">{{ channel.description }}</p> -->
              <!-- <p class="card-text"><small class="text-muted">Last updated {{ channel.lastUpdated }}</small></p> -->
            </div>
          </div>
        </div>
        
        <!-- Card for creating a channel -->
        <div class="col">
          <div class="card h-100">
            <img :src="addCoverUrl" class="card-img-top img-fluid" alt="">
            <div class="card-body d-flex flex-column justify-content-center">
              <h5 class="card-title">Создать канал</h5>
              <p class="card-text">Нажмите здесь, чтобы создать новый канал.</p>
              <!-- You can add a button or link to trigger the creation of a new channel -->

              <router-link to="/channels">
                <button class="btn btn btn-primary">Создать канал</button>
              </router-link>
              <div>
                <a :href="'https://t.me/' + configData.bot_username" class="btn btn-outline-primary mt-3" target="_blank" v-if="isAuthenticated">Открыть в Telegram</a>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>


    
  </div>

 <div class="main-component bg-white shadow-sm rounded py-3 ps-4 pe-2 mt-2" v-if="configData && configData.bot_username == 'harikatha_bot'">
    <h1>Хари Катха</h1>
    <p>«Хари Катха» — это место, где мы собираем вдохновляющие лекции проповедников традиции бхакти-йоги, чтобы помочь вам углубить свои знания. </p>
    <p>Мы не просто предлагаем видео — каждую лекцию сопровождают текстовые конспекты и выжимки, а тесты для самопроверки помогут закрепить понимание. Всё сделано для того, чтобы ваше духовное обучение было глубоким, системным и удобным.</p>

    <p>
      <b>Платформа «Хари Катха» предлагает:</b>
      <ul>
        <li><b>Удобный глобальный поиск по темам</b> — найдите, где в лекциях разных учителей упоминаются интересующие вас вопросы.</li>
        <li><b>Текстовые конспекты и выжимки</b> — ключевые моменты лекций для быстрого восприятия информации.</li>
        <li><b>Тесты для самопроверки</b> — чтобы вы могли убедиться в усвоении материала.</li>
        <li><b>Лекции различных проповедников</b>, представленных на одной платформе, с возможностью предлагать новых учителей.</li>
      </ul>
    </p>


    <div class="row mb-3 mt-5">
      <div class="col-12">
        <SearchBarComponent searchType="global"/>
      </div>
    </div>


    <div >
      <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 mt-3">
        <!-- Loop for existing channels -->
        <div v-for="channel in channels" :key="channel.id" class="col">
          <div class="card h-100">
            <img :src="channel.logo_square_thumbs.middle" class="card-img-top img-fluid" :alt="channel.caption" v-if="channel.logo_square_thumbs.middle">
            <img :src="channelCoverUrl" class="card-img-top img-fluid" :alt="channel.caption" v-if="!channel.logo_square_thumbs.middle">

            <div class="card-body d-flex flex-column">
              <h5 class="card-title">{{ channel.caption }}</h5>
              <div class="small d-flex align-items-center mb-2" ><span class="badge bg-primary">{{ channel.stats.total.count }} видео</span> <span class="text-muted ps-2"><i class="bi bi-clock"></i> {{channel.stats.total.duration_hours}}</span></div>
              <div class="progress">
                  <div class="progress-bar bg-success" role="progressbar" 
                       style="width: {{ (channel.stats.processed.count / channel.stats.total.count) * 100 }}%;" 
                       aria-valuenow="{{ channel.stats.processed.count }}" 
                       aria-valuemin="0" 
                       aria-valuemax="{{ channel.stats.total.count }}">
                      <span class="visually-hidden">{{ channel.stats.processed.count }} из {{ channel.stats.total.count }} обработано</span>
                  </div>
              </div>
              <div class="small text-muted">
                {{ channel.stats.processed.count }} из {{ channel.stats.total.count }} обработано
              </div>


<!--               <p class="card-text">Обработано видео {{ channel.stats.processed.count }} из  {{ channel.stats.total.count }}</p>
              <p class="card-text">Оплачено {{ channel.stats.processed.sum }} из  {{ channel.stats.total.sum }}</p>
 -->
              <a :href="channel.link" class="btn btn btn-primary mt-3">Открыть</a>

              <!-- You can add more information about the channel here -->
              <!-- For example: -->
              <!-- <p class="card-text">{{ channel.description }}</p> -->
              <!-- <p class="card-text"><small class="text-muted">Last updated {{ channel.lastUpdated }}</small></p> -->
            </div>
          </div>
        </div>
        
        <!-- Card for creating a channel -->
        <div class="col" v-if="isAuthenticated">
          <div class="card h-100">
            <img :src="addCoverUrl" class="card-img-top img-fluid" alt="">
            <div class="card-body d-flex flex-column justify-content-center">
              <h5 class="card-title">Создать канал</h5>
              <p class="card-text">Нажмите здесь, чтобы создать новый канал.</p>
              <!-- You can add a button or link to trigger the creation of a new channel -->

              <router-link to="/channels">
                <button class="btn btn btn-primary">Создать канал</button>
              </router-link>
              <div>
                <a :href="'https://t.me/' + configData.bot_username" class="btn btn-outline-primary mt-3" target="_blank" v-if="isAuthenticated">Открыть в Telegram</a>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <p v-if="!isAuthenticated" class="mt-4">Войдите, чтобы начать пользоваться платформой.</p>
    <telegram-auth v-if="!isAuthenticated && !configData.disable_telegram_auth"></telegram-auth>

    
  </div> 
</template>

<script>
import channelCover from '@/assets/channel-cover.jpg';
import addCover from '@/assets/add-cover.jpg';

import { mapGetters, mapActions, mapMutations } from 'vuex';
import { computed } from 'vue';
import { useStore } from 'vuex';
import TelegramAuth from './TelegramAuth.vue';
import SearchBarComponent from './SearchBarComponent.vue';

export default {
  name: 'MainComponent',
  components: {
    TelegramAuth,
    SearchBarComponent
  },
  data() {
    return {
      channelCoverUrl: channelCover,
      addCoverUrl: addCover
    };
  },
  computed: {
    ...mapGetters('channel', ['getChannelItem', 'getChannels']),
    ...mapGetters('app', ['isMobile', 'configData', 'chatbotName']), 
    channelItem: {
      get() {
        return this.getChannelItem || {};
      },
      set(value) {
        this.updateChannelItem(value);
      },
    },
    channels: {
      get() {
        return this.getChannels || [];
      },
      set(value) {
        this.$store.commit('channel/setChannels', value);
      },
    }

  },  

  methods:{
    ...mapMutations('channel', ['setChannelItem', 'setChannels']),
    ...mapActions('channel', ['fetchChannel', 'saveChannel', 'deleteChannel', 'editMaterial', 'deleteMaterial', 'getMaterialsForChannel', 'fetchChannels', 'saveChannelCoverImage', 'saveChannelLogo']),

    fetchChannels() {
      this.listLoading = true;

      this.setChannels([]);

      this.$store.dispatch('channel/fetchChannels')
        .then((data) => {
          console.log(data);
          this.setChannels(data.channels);
          this.listLoading = false;

          // Обработка успешной загрузки списка подборок
          //this.channels = data.list;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
          // Обработка ошибок при загрузке списка подборок
        });
    }
  },

  created() {
    // Вызов экшена для загрузки подборки
    this.fetchChannels();
  },  

  setup() {
    
    const store = useStore();
    const isAuthenticated = computed(() => store.getters['user/isAuthenticated']);
    return {
      isAuthenticated,
    
    };
  }

};
</script>

<style>
</style>
