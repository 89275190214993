<template>
  <div class="search-component bg-white shadow-sm rounded py-3 ps-4 pe-2 mt-2">
    <h1>Результаты поиска для: "{{ searchQuery }}"</h1>

    <div v-if="isLoading">
      <p>Загрузка...</p>
    </div>

    <div v-if="error">
      <p>Произошла ошибка: {{ error }}</p>
    </div>

    <div v-if="searchResults.length > 0">
      <!-- Выводим список результатов поиска, сгруппированных по queue_id -->
      <ul class="list-group list-group-flush">
        <li v-for="result in searchResults" :key="result.queue_id" class="list-group-item px-2 px-md-3">
          <div class="d-flex flex-column flex-md-row align-items-start">
            <!-- Левая колонка (изображение и заголовок) -->
            <div class="d-flex flex-column flex-md-row align-items-start w-100 me-2">
              <div class="col-auto col-md-2 px-0 me-md-2">
                <img :src="result.cover_image" alt="" class="thumb me-2 img-fluid" v-if="result.cover_image">
                <div class="thumb empty-thumb" v-if="!result.cover_image"><i class="bi bi-camera"></i></div>
              </div>

              <!-- Информация о видео -->
              <div class="col">
                <h5 class="mb-0"><a :href="result.article_url">{{ result.video_title }}</a></h5>
                <div class="text-muted small">
                  <i class="bi bi-calendar"></i> Создан: {{ result.timeadd }}
                  <span v-if="result.channel_caption" class="ms-3"><i class="bi bi-person"></i> {{ result.channel_caption }}</span>
                </div>

                <!-- Фрагменты видео -->
                <div v-for="fragment in result.fragments || []"
                     :key="fragment.id"
                     class="fragment-item d-flex align-items-center fragment py-1"
                     style="cursor: pointer;">
                  <!-- <span class="text-muted small fragment-time">{{ formatTime(fragment.start_time) }}</span> -->
                  <span class="ms-2">{{ fragment.fragment_text.slice(0, 150) }}...</span>

                  <a :href="result.article_url +'#time' + fragment.start_time" 
                     target="_blank" 
                     class="btn btn-light btn-sm me-3 nowrap ">найти в тексте</a>

                  <ButtonVideoComponent :startTime="fragment.start_time" :youtubeLink="result.youtube_link"></ButtonVideoComponent>
<!--                   <a :href="getYouTubeLinkWithTime(result.article_url, fragment.start_time)" 
                     target="_blank" 
                     class="btn btn-primary btn-sm ms-3">Открыть</a>
 -->                     
                </div>

              </div>
            </div>

          </div>
        </li>
      </ul>
    </div>

    <div v-else-if="!isLoading && searchResults.length === 0">
      <p>Ничего не найдено.</p>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';
import ButtonVideoComponent from '@/components/ButtonVideoComponent.vue';

export default {
  components: {
      ButtonVideoComponent
  },
  
  data() {
    return {
      searchQuery: this.$route.params.query || '', // Получение строки запроса
      searchType: this.$route.params.type || 'global', // Получение типа поиска
    };
  },
  computed: {
    ...mapGetters('search', ['getSearchResults', 'getIsLoading', 'getError']),
    searchResults() {
      return this.getSearchResults.map(result => ({
        ...result,
        fragments: Array.isArray(result.fragments)
          ? result.fragments.map(fragment => ({
              ...fragment,
              isExpanded: fragment.isExpanded || false, // Инициализируем isExpanded как false, если его нет
            }))
          : [], // Если фрагментов нет, возвращаем пустой массив
      }));
    },
    isLoading() {
      return this.getIsLoading;
    },
    error() {
      return this.getError;
    }
  },
  methods: {
    ...mapActions('search', ['performSearch']),
    
    // Метод для форматирования времени в секундах в формат времени
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    },
    
    // Метод для получения ссылки с таймкодом для YouTube
    getYouTubeLinkWithTime(articleUrl, startTime) {
      return `${articleUrl}?start=${Math.floor(startTime)}`;
    }
  },
  created() {
    // Выполняем поиск с учётом типа поиска (глобальный или по каналу)
    console.log({ query: this.$route.params.query, type: this.$route.params.type, contextId: this.$route.params.contextId });

    this.performSearch({
      searchQuery: this.$route.params.query,
      searchType: this.$route.params.type,
      contextId: this.$route.params.contextId
    });    
  },
watch: {
  '$route.params': {
    handler(newParams, oldParams = {}) { // Добавляем значение по умолчанию для oldParams
      const { query: newQuery, type: newType } = newParams;
      const { query: oldQuery = '', type: oldType = '' } = oldParams; // Проверка на undefined

      if (newQuery !== oldQuery || newType !== oldType) {
        this.searchQuery = newQuery; // Обновляем searchQuery
        this.performSearch({
          searchQuery: newQuery,
          searchType: newType,
          contextId: this.$route.params.contextId
        });
      }
    },
    deep: true, // Наблюдаем за всеми параметрами объекта
    immediate: true // Запускаем обработчик сразу при монтировании компонента
  }
}


};

</script>


<style scoped>
.thumb {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-right: 10px;
}

.empty-thumb {
  width: 120px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
}

.fragment-item {
  border-bottom: 1px solid #eee;
}
.fragment-time{
  min-width: 80px;
}
.fragment-details {
  background-color: #f9f9f9;
  padding: 0.5rem;
  border-radius: 5px;
  display: none;
}

.expanded .fragment-details {
  display: block;
}
</style>
